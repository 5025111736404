import { useEffect, useState } from "react";
import { Header } from "./Header";
import Footer from "./Footer";
import PDPANotice from "components/PDPANotice";
import { useParams } from "react-router-dom";

export const Layout = ({ children }) => {
  const [isTransparent, setIsTransparent] = useState(true);
  const { lang } = useParams();
  const isHome =
    window.location.pathname === `/${lang}` ||
    window.location.pathname === `/${lang}/`;
  const isPricingPage = window.location.pathname === `/${lang}/pricing`;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setIsTransparent(false);
      } else {
        setIsTransparent(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="overflow-hidden ">
      <Header isTransparent={isTransparent} />
      <div className={`w-screen ${isHome || isPricingPage ? "mb-0" : "mb-10"}`}>
        {children}
      </div>
      <PDPANotice />
      <Footer />
    </div>
  );
};
