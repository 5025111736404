import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const PDPANotice = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if the user has already acknowledged the notice
    const pdpaAcknowledged = localStorage.getItem("pdpaAcknowledged");
    if (!pdpaAcknowledged) {
      setIsVisible(true);
    }
  }, []);

  const handleAcknowledge = () => {
    // Save the acknowledgment to local storage
    localStorage.setItem("pdpaAcknowledged", "true");
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="fixed bottom-0 w-full z-20">
      <div className="bg-gray-900 text-white text-center p-4 m-4 rounded-lg">
        <div className="container mx-auto flex justify-between items-center">
          <p>
            We value your privacy. By using our site, you acknowledge that you
            have read and understand our{" "}
            <Link
              className="underline"
              to="https://nexpie.com/privacypolicy.pdf"
            >
              Privacy Policy
            </Link>
            .
          </p>
          <button
            onClick={handleAcknowledge}
            className="bg-white text-gray-900 py-2 px-4 rounded-lg"
          >
            Acknowledge
          </button>
        </div>
      </div>
    </div>
  );
};

export default PDPANotice;
