import React, { useState } from "react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const FAQItem = ({
  question,
  answer,
  index,
  expandedIndex,
  setExpandedIndex,
}) => {
  const isExpanded = index === expandedIndex;

  const toggleFAQ = () => {
    setExpandedIndex(isExpanded ? null : index);
  };

  return (
    <li>
      <button
        className="relative flex gap-2 items-center w-full py-5 text-base font-normal text-left border-t md:text-lg border-base-content/10"
        aria-expanded={isExpanded}
        onClick={toggleFAQ}
      >
        <span className="flex-1 text-base-content">{question}</span>
        <svg
          className="flex-shrink-0 w-4 h-4 ml-auto fill-current"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`transform origin-center transition duration-200 ease-out ${
              isExpanded ? "" : "-rotate-90"
            }`}
          />
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className="transform origin-center transition duration-200 ease-out"
          ></rect>
        </svg>
      </button>
      <div
        className="transition-all duration-300 ease-in-out max-h-0 overflow-hidden"
        style={{
          maxHeight: isExpanded ? "1000px" : "0",
          transition: "max-height 0.3s ease-in-out",
        }}
      >
        <div className="pb-5 leading-relaxed">
          <div className="space-y-2 leading-relaxed text-gray-500 font-light">
            {parse(answer)}
          </div>
        </div>
      </div>
    </li>
  );
};

const FAQComponent = ({ faqList }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const { t } = useTranslation();

  return (
    <div className="px-8 max-w-5xl mx-auto flex flex-col md:flex-row gap-12">
      <div className="flex flex-col text-left basis-1/2">
        <p className="text-[#333333]">NEXIIOT FAQ</p>
        <p className="sm:text-4xl text-3xl text-primary font-medium text-base-content">
          {t("faq")}
        </p>
      </div>
      <ul className="basis-1/2">
        {faqList &&
          faqList.map((faq, index) => (
            <FAQItem
              key={`faq_${faq.id}`}
              index={index}
              question={faq.question}
              answer={faq.answer}
              expandedIndex={expandedIndex}
              setExpandedIndex={setExpandedIndex}
            />
          ))}
      </ul>
    </div>
  );
};

export default FAQComponent;
