import React from "react";
import { PrimaryButton } from "components/Button";
import "../customStyle.css";
import { useTranslation } from "react-i18next";

export const Start = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-gray-50">
      <div className="max-w-screen-xl mx-auto mt-4 py-20 px-2">
        <div className="pb-10 mx-auto text-center">
          <h2 className="text-3xl font-medium text-primary">
            {t("excitedToStart")}
          </h2>
        </div>
        <div className="flex justify-center w-full px-4">
          <PrimaryButton
            className="text-x px-9 py-3 w-full md:w-auto rounded-md"
            style={{
              backgroundColor: "#ffc414",
              borderColor: "#ffc414",
              color: "#333333",
            }}
            link="https://auth.nexiiot.io/signup"
          >
            {t("getStart")}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};
