import { useQuery } from "@apollo/client";
import { GET_FAQ } from "utils/apollo/graphql/faq";
import FAQComponent from "components/FaqComponent";

const defaultQa = [
  {
    id: 1,
    question: "When will the server come back?",
    answer:
      "The server will come back when it's done taking a coffee break. ☕️",
  },
  {
    id: 2,
    question: "Why is the server down?",
    answer:
      "The server is down because it heard you were talking behind its back. 🤫",
  },
];

export const FAQ = () => {
  const { loading, data } = useQuery(GET_FAQ);
  const faqList = loading ? defaultQa : data?.faq?.question || defaultQa;

  return (
    <div className="max-w-screen-xl mx-10 lg:mx-auto my-32">
      <FAQComponent faqList={faqList} />
    </div>
  );
};
