import { gql } from "@apollo/client";

const GET_PRICING = gql`
  query pricing($locale: String) {
    pricing(locale: $locale) {
      updated_at
      title
      description
      pricingCard {
        name
        recommend
        coverImage {
          url
        }
        price
        limited {
          name
          value
        }
        consumable {
          name
          value
        }
      }
    }
  }
`;

export { GET_PRICING };
