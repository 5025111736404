import { PrimaryButton, PrimaryOutlineButton } from "components/Button";
import { Dropdown } from "components/Dropdown";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { GlobeAltIcon } from "@heroicons/react/24/outline";

const backendUrl = process.env.REACT_APP_STRAPI_URL;
const authUrl = process.env.REACT_APP_PLATFORM_AUTH_URL;
const portalUrl = "https://portal.nexiiot.io";
const signupUrl = "https://auth.nexiiot.io/signup";

const LoginButton = ({ children, className }) => (
  <div>
    <PrimaryOutlineButton
      className={`block ${className} rounded-md`}
      color="white"
      link={portalUrl}
      external
    >
      <p className="group-hover:text-primary">{children}</p>
    </PrimaryOutlineButton>
  </div>
);

const Profile = ({ className, btnClassName }) => {
  const [user, setUser] = useLocalStorage("username");
  const [jwt, setJwt] = useLocalStorage("jwt");
  const [isLogged, setIsLogged] = useState(!!jwt);

  const { t } = useTranslation();

  const handleLogout = (e) => {
    e.preventDefault();
    fetch(`${authUrl}/logout`).then(() => {
      setUser(null);
      setJwt(null);
      setIsLogged(false);
    });
  };
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <header className={`flex flex-col md:flex-row gap-1 ${className}`}>
      {isLogged ? (
        <Dropdown
          label={user}
          options={[
            [{ label: "Portal", link: portalUrl }, { label: "Forum" }],
            [
              { label: "Account settings" },
              { label: "Support" },
              { label: "License" },
            ],
            [{ label: "Sign out", onclick: handleLogout }],
          ]}
        />
      ) : (
        <>
          <LoginButton className={`hidden md:block ${btnClassName}`}>
            {t("signIn")}
          </LoginButton>
          <PrimaryButton
            className={`block md:hidden ${className} rounded-md`}
            color="white"
            link={portalUrl}
            external
          >
            <p className="group-hover:text-primary">{t("signIn")}</p>
          </PrimaryButton>
          <PrimaryButton
            className={`block ${className} rounded-md`}
            style={{
              backgroundColor: "#ffc414",
              borderColor: "#ffc414",
              color: "#333333",
            }}
            link={signupUrl}
            external
          >
            {t("getStart")}
          </PrimaryButton>
        </>
      )}
    </header>
  );
};

export { Profile };

// -- Commented Code for Reference --

// const SignInButton = (props) => (
//   <a href={`${backendUrl}/connect/${props.providerName}`}>
//     <GhostButton borderLess>
//       <p className="group-hover:text-primary">Sign in</p>
//     </GhostButton>
//   </a>
// );

// use this if we can use auth from strapi
// link={`${backendUrl}/connect/${props.providerName}`}

// language changer button
{
  /* <div className="pt-2">
  <Dropdown
    label={<GlobeAltIcon className="w-8" />}
    options={[
      { label: "Thai", link: `/th` },
      { label: "English", link: `/en` },
    ]}
  />
</div>; */
}
