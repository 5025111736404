import React from "react";
import { format } from "date-fns";
import { useQuery } from "@apollo/client";
import Loading from "components/Loading";
import NotFound from "components/NotFound";
import { Layout } from "components/layout";
import { GET_PRICING } from "utils/apollo/graphql/pricing";
import PricingTable from "components/PricingTable";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";

const Pricing = () => {
  const { loading, error, data } = useQuery(GET_PRICING);
  const { t } = useTranslation();

  if (loading) {
    return <Loading />;
  }

  if (error || !data.pricing) {
    return <NotFound className="mt-48" />;
  }

  return (
    <Layout>
      <div className="min-h-screen">
        <PricingDetailsSection data={data} t={t} />
        <ServiceUsageSection t={t} />
      </div>
    </Layout>
  );
};

const PricingDetailsSection = ({ data, t }) => (
  <div className="my-24 px-4 max-w-screen-xl mx-auto">
    <div className="text-center mb-2 pt-6">
      <p className="text-4xl font-medium text-primary">{data.pricing.title}</p>
      <p className="text-[#979797] mt-2">
        {t("update")}: {format(new Date(data.pricing.updated_at), "dd/MM/yyyy")}
      </p>
    </div>

    <PricingTable packages={data.pricing.pricingCard} />
  </div>
);

const ServiceUsageSection = ({ t }) => (
  <div className="w-full bg-[#f5f5f5]">
    <div className="max-w-screen-xl mx-auto mt-4 py-20 px-2">
      <div className="pb-10 mx-auto text-center">
        <h2 className="text-3xl font-medium text-primary">
          {t("serviceUsage")}
        </h2>
        <p className="text-xl text-[#979797] mt-4">{t("howToCalculate")}</p>
      </div>
      <div className="flex justify-center w-full px-2 items-center">
        <Link
          to={"https://docs.nexiiot.io/usage-calculate.html"}
          target="_blank"
          rel="noopener noreferrer"
          className="text-xl font-normal text-primary flex items-center"
        >
          {t("viewMore")}
          <ArrowRightCircleIcon className="w-7 ml-1" />
        </Link>
      </div>
    </div>
  </div>
);

export default Pricing;
