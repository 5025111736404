import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";

import { HomePage } from "pages/Home";
import LoginRedirect from "pages/LoginRedirect";
import BlogList from "pages/BlogList";
import { Blog } from "pages/Blog";
import Pricing from "pages/Pricing";
import AppRedirect from "pages/AppRedirect";
import TermsOfUse from "pages/TermsOfUse";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import i18n from "./i18n"; // import your i18n configuration

const LanguageWrapper = ({ children }) => {
  const { lang } = useParams();

  useEffect(() => {
    if (lang && ["en", "th"].includes(lang)) {
      i18n.changeLanguage(lang);
    } else {
      i18n.changeLanguage("th");
    }
  }, [lang]);

  return children;
};

function App() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/th" />} />
      <Route path="/:lang">
        <Route
          path=""
          element={
            <LanguageWrapper>
              <HomePage />
            </LanguageWrapper>
          }
        />
        <Route
          path="connect/:providerName/redirect"
          element={<LoginRedirect />}
        />
        <Route path="terms-of-use" element={<TermsOfUse />} />

        {/* ----- blogs ----- */}
        <Route path="blog" element={<BlogList />} />
        <Route path="blog/categories/:categoryName" element={<BlogList />} />
        <Route path="blog/:blogName" element={<Blog />} />

        {/* pricing */}
        <Route path="pricing" element={<Pricing />} />

        {/* application */}
        <Route path="appredirect" element={<AppRedirect />} />
      </Route>
    </Routes>
  );
}

export default App;
