import React, { useEffect, useState } from "react";

const iosurl = "https://apps.apple.com/th/app/nexiiot/id6451468761";
const androidurl =
  "https://play.google.com/store/apps/details?id=com.nexpie.piedash.nexiiot";

const AppRedirect = () => {
  const [platform, setPlatform] = useState("");
  useEffect(() => {
    setPlatform(getPlatform());
  }, []);
  useEffect(() => {
    switch (platform) {
      case "ios":
        window.location.href = iosurl;
        break;
      case "android":
        window.location.href = androidurl;
        break;
      default:
        break;
    }
  }, [platform]);
  const getPlatform = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "ios";
    }

    return "unknown";
  };
  return (
    <div className="h-screen w-screen text-center">
      redirecting to {platform}
    </div>
  );
};

export default AppRedirect;
