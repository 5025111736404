import QRCode from "qrcode";
import React, { useState, useEffect } from "react";

const QRCodeScanner = (props) => {
  const [qrCodeDataURL, setQRCodeDataURL] = useState("");

  useEffect(() => {
    generateQR();
  }, []);

  // With async/await
  const generateQR = async () => {
    try {
      const dataURL = await QRCode.toDataURL(
        `${window.location.href}appredirect`
      );
      setQRCodeDataURL(dataURL);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <img
      src={qrCodeDataURL}
      alt="QR Code"
      className="rounded-lg overflow-hidden"
      {...props}
    />
  );
};

export default QRCodeScanner;
