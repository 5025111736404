import React, { useRef, useEffect, useMemo, memo } from "react";
import { PrimaryButton } from "./Button";
import { useTranslation } from "react-i18next";

const supportMail = "contact@nexpie.com";

const tableColor = [
  "#ffffff",
  "#fbfcff",
  "#f7faff",
  "#f4f8ff",
  "#f0f6ff",
  "#edf4ff",
  "#e9f2ff",
  "#e6f0ff",
  "#e2eeff",
  "#deebff",
];

const formatNumber = (num) => {
  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
  } else if (num >= 10_000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  } else {
    return num.toLocaleString("th-TH");
  }
};

const generateMailTemplate = (email, pkg, lang) => {
  const subject = `${lang === "th" ? "สนใจ " : "Interested in "} ${pkg.name}`;
  const body =
    lang === "th"
      ? `ถึงทีมสนับสนุน NEXIIOT,\n\nฉันมีความสนใจในการสั่งซื้อ ${pkg.name}.\n\nกรุณาติดต่อกลับเพื่อให้ข้อมูลเพิ่มเติม:`
      : `Dear NEXIIOT support team,\n\nI am interested in placing an order for ${pkg.name}.\n\nPlease contact me for further details:`;

  const contact =
    lang === "th"
      ? `ชื่อ: [กรอกชื่อของคุณที่นี่]\nโทร: [กรอกเบอร์โทรของคุณที่นี่]`
      : `Name: [Please insert your name]\nTelephone: [Please insert your phone number]`;

  const additionalInfo =
    lang === "th"
      ? `รายละเอียดการใช้งาน: [กรอกรายละเอียดการใช้งานของคุณที่นี่]`
      : `Usage details: [Please provide usage details]`;

  return `mailto:${email}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(
    body + "\n\n" + contact + "\n\n" + additionalInfo
  )}`;
};

const ServiceRow = memo(({ service, packages, type, t }) => {
  return (
    <tr>
      <th className="px-4 py-2 border sticky left-0 bg-white z-10 text-left">
        <p className="font-normal">{t(service.key)}</p>
        {service.description && (
          <p className="text-[#959595] text-xs font-light">
            ({service.description})
          </p>
        )}
        {service.key === "datasource" && (
          <p className="text-[#959595] text-xs font-light">
            * 1 GB = 1,000,000,000 Bytes
          </p>
        )}
      </th>
      {packages.map((pkg, index) => {
        const item = pkg[type].find((it) => it.name === service.key);
        return (
          <td
            key={pkg.name}
            className="px-4 py-2 border text-center"
            style={{ backgroundColor: tableColor[index] }}
          >
            {item ? formatNumber(item.value) : "-"}
          </td>
        );
      })}
    </tr>
  );
});

const PricingTable = ({ packages }) => {
  const { t, i18n } = useTranslation();
  const tableContainerRef = useRef(null);

  const limitedServices = useMemo(
    () => [
      { key: "device" },
      { key: "project" },
      { key: "member" },
      { key: "dashboard" },
      { key: "datatag" },
    ],
    []
  );

  const consumableServices = useMemo(
    () => [
      { key: "msg", description: t("messages per month") },
      { key: "storage", description: t("point - month") },
      { key: "shadow", description: t("operations per month") },
      { key: "api", description: t("operations per month") },
      { key: "trigger", description: t("operations per month") },
      { key: "datasource", description: t("GB* per month") },
    ],
    [t]
  );

  useEffect(() => {
    const tableContainer = tableContainerRef.current;
    let isDown = false;
    let startX, scrollLeft;

    const mouseDownHandler = (e) => {
      isDown = true;
      tableContainer.style.cursor = "grabbing";
      startX = e.pageX - tableContainer.offsetLeft;
      scrollLeft = tableContainer.scrollLeft;
    };

    const mouseLeaveHandler = () => {
      isDown = false;
      tableContainer.style.cursor = "grab";
    };

    const mouseUpHandler = () => {
      isDown = false;
      tableContainer.style.cursor = "grab";
    };

    const mouseMoveHandler = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - tableContainer.offsetLeft;
      const walk = (x - startX) * 0.7; // scroll-fast
      tableContainer.scrollLeft = scrollLeft - walk;
    };

    tableContainer.addEventListener("mousedown", mouseDownHandler);
    tableContainer.addEventListener("mouseleave", mouseLeaveHandler);
    tableContainer.addEventListener("mouseup", mouseUpHandler);
    tableContainer.addEventListener("mousemove", mouseMoveHandler);

    return () => {
      tableContainer.removeEventListener("mousedown", mouseDownHandler);
      tableContainer.removeEventListener("mouseleave", mouseLeaveHandler);
      tableContainer.removeEventListener("mouseup", mouseUpHandler);
      tableContainer.removeEventListener("mousemove", mouseMoveHandler);
    };
  }, []);

  return (
    <div className="container mx-auto max-w-screen-xl">
      <style>{`
        .scroll-container {
          cursor: grab;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
        }
        .scroll-container:active {
          cursor: grabbing;
        }

        @media (max-width: 768px) {
          .scroll-container {
            overflow-x: scroll;
          }
        }
      `}</style>
      <p className="text-right mb-2 text-[#959595] text-xs sticky right-0">
        {t("*vat")}
      </p>
      <div ref={tableContainerRef} className="scroll-container">
        <table className="table-fixed w-[1586px] relative">
          <thead>
            <tr>
              <th className="w-[226px] sticky left-0 bg-white z-10" />
              {packages.map((pkg, index) => (
                <th
                  key={pkg.name}
                  className="px-4 py-2 font-medium"
                  style={{ backgroundColor: tableColor[index] }}
                >
                  <img
                    src={`${process.env.REACT_APP_STRAPI_URL}${pkg.coverImage.url}`}
                    alt={`image_${pkg.name}`}
                    className="w-[120px] object-contain mx-auto"
                  />
                  <p>{pkg.name}</p>
                  <p className="font-light text-xs">
                    {console.log("pkg.price", pkg.price)}
                    {pkg.price === 0
                      ? t("free 1 month")
                      : `(${pkg.price.toLocaleString("th-TH", {
                          style: "currency",
                          currency: "THB",
                          maximumFractionDigits: 0,
                        })} /${t("month")})`}
                  </p>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className="px-4 py-2 text-[#034D82] font-medium text-left w-[188px] sticky left-0 bg-white z-10">
                {t("limited usage")}
              </th>
              {packages.map((_, index) => (
                <th
                  key={index}
                  className="px-4 py-2"
                  style={{ backgroundColor: tableColor[index] }}
                />
              ))}
            </tr>
            {limitedServices.map((service) => (
              <ServiceRow
                key={service.key}
                service={service}
                packages={packages}
                type="limited"
                t={t}
              />
            ))}
            <tr>
              <th className="px-4 pb-2 pt-6 text-[#034D82] font-medium text-left sticky left-0 bg-white z-10">
                {t("consumable usage")}
              </th>
            </tr>
            {consumableServices.map((service) => (
              <ServiceRow
                key={service.key}
                service={service}
                packages={packages}
                type="consumable"
                t={t}
              />
            ))}
            <tr>
              <th className="w-[182px] sticky left-0 bg-white z-10" />
              {packages.map((pkg, index) => (
                <td
                  key={"btn_" + pkg.name}
                  className="px-4 py-6"
                  style={{ backgroundColor: tableColor[index] }}
                >
                  <PrimaryButton
                    className="text-sm py-3 w-[140px] rounded-full mx-auto"
                    style={{
                      backgroundColor: pkg.price === 0 ? "#ffc414" : "#0675C5",
                      borderColor: pkg.price === 0 ? "#ffc414" : "#0675C5",
                      color: pkg.price === 0 ? "#333333" : "#FFFFFF",
                    }}
                    link={
                      pkg.price === 0
                        ? "https://auth.nexiiot.io/signup"
                        : generateMailTemplate(supportMail, pkg, i18n.language)
                    }
                    external={pkg.price === 0 ? true : false}
                    children={
                      pkg.price === 0 ? t("start free trial") : t("place order")
                    }
                  />
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PricingTable;
