import { FacebookIcon } from "./facebook";
import { InstagramIcon } from "./instagram";
import { TwitterIcon } from "./twitter";
import { LinkedinIcon } from "./linkedin";
import { MediumIcon } from "./medium";
import { LineIcon } from "./line";
import { DiscordIcon } from "./discord";
import { YoutubeIcon } from "./youtube";
import { TiktokIcon } from "./tiktok";

export const icons = {
  facebook: <FacebookIcon />,
  ig: <InstagramIcon />,
  twitter: <TwitterIcon />,
  linkedin: <LinkedinIcon />,
  medium: <MediumIcon />,
  line: <LineIcon />,
  discord: <DiscordIcon />,
  youtube: <YoutubeIcon />,
  tiktok: <TiktokIcon />,
};
