import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

// Import translations (example)
// You can organize your translations in separate JSON files
import translationEN from "./locales/en/translation.json";
import translationTH from "./locales/th/translation.json";

const resources = {
  en: { translation: translationEN },
  th: { translation: translationTH },
};

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    detection: {
      order: ["path", "cookie", "localStorage", "sessionStorage", "navigator"],
      caches: ["cookie"],
    },
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

export default i18n;
