import image from "../assets/data-not-found.png";
import { PrimaryButton } from "./Button";

const NotFound = ({ title = "Content", className }) => (
  <div className={`justify-center items-center text-center ${className}`}>
    <img src={image} alt="content not found" className="mx-auto w-32" />
    <p className="mx-auto mt-4 text-xl">{title} not found</p>
    <PrimaryButton
      children="Back to Home"
      link="/"
      className="mt-4 flex rounded-md"
    />
  </div>
);

export default NotFound;
