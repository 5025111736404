import { useState, useEffect } from "react";

function useUrlFormat(inputString) {
  const [urlString, setUrlString] = useState("");

  useEffect(() => {
    const formattedString = inputString
      .toLowerCase() // convert to lowercase
      .replace(/\s+/g, "-") // replace spaces with hyphens
      .replace(/[^a-z0-9-]/g, "") // remove non-alphanumeric characters
      .replace(/--+/g, "-") // replace consecutive hyphens with a single one
      .replace(/^-+|-+$/g, ""); // remove leading/trailing hyphens
    setUrlString(formattedString);
  }, [inputString]);

  return urlString;
}

export default useUrlFormat;
