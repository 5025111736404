import { Layout } from "components/layout";
import React from "react";

const TermsOfUse = () => {
  return (
    <Layout>
      <div className="mt-24 h-full max-w-screen-lg mx-auto px-4 py-8">
        <div className="">
          <h1 className="text-3xl font-bold mb-8">Terms of Use</h1>

          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Introduction
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                This Term of Service was last updated on February 20, 2020
              </p>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Use of the Service
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                    In order to use the Services, you must register and create
                    an account by providing Personal Data, such as Your Name,
                    Organization, Email Address, and Mobile Phone Number. You
                    may need to provide certain registration details or other
                    information on behalf of yourself or other users to create
                    an account and to otherwise access and use the Services. It
                    is a condition of your access and use of the Services that
                    all the information you provide is correct, current, and
                    complete.
                  </dd>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Responsibilities
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                    When you create an account with us, you must provide us with
                    information that is accurate, complete, and current at all
                    times. Failure to do so constitutes a breach of the Terms,
                    which may result in immediate termination of your account on
                    our Service. You are responsible for safeguarding the
                    password that you use to access the Service and for any
                    activities or actions under your password, whether your
                    password is with our Service or a third-party service. You
                    agree not to disclose your password to any third party. You
                    must notify us immediately upon becoming aware of any breach
                    of security or unauthorized use of your account. You are
                    responsible for all applicable fees associated with the use
                    of the Services in connection with the NEXIIOT Platform.
                  </dd>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Security and Data Privacy
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                    <span className="font-bold">
                      1. The security of your Personal Information is important
                      to us, but remember that no method of transmission over
                      the Internet, or method of electronic storage, is 100%
                      secure.
                    </span>{" "}
                    While we strive to use commercially acceptable means to
                    protect your Personal Information, we cannot guarantee its
                    absolute security.
                    <br />
                    <br />
                    <span className="font-bold">
                      2. Whenever you, your agents or End-Users interact with
                      our Services, We automatically receive and record
                      information on Our server logs from the browser or device,
                      which may include IP address, “cookie” information, the
                      type of browser and/or device being used to access the
                      Services.
                    </span>{" "}
                    “Cookies” are identifiers We transfer to the browser or
                    device of your agents or End-Users that allow Us to
                    recognize agent or End-User and their browser or device
                    along with how our Services are being utilized. When We
                    collect this information, We only use this data in aggregate
                    form, and not in a manner that would identify your agents or
                    End-Users personally. For example, this aggregate data can
                    tell Us how often users use a particular feature of the
                    Services, and We can use that knowledge to improve the
                    Services.
                    <br />
                    <br />
                    <span className="font-bold">
                      3. We receive and store any information you knowingly
                      provide to Us.
                    </span>{" "}
                    For example, through the registration process for Our
                    Services and/or through your account settings, We may
                    collect Personal Data, such as your name, email address,
                    phone number, credit card information. In addition, We may
                    communicate with you. For example, We may send you product
                    announcements and promotional offers or contact you about
                    your use of the Services. If you do not want to receive
                    communications from Us, please indicate this preference by
                    sending an email to contact@nexpie.com and provide us with
                    the name and email address that no longer wishes to receive
                    these communications.
                  </dd>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Fees and Payment
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                    <span className="font-bold">1. Service Options</span>
                    <br />
                    <span className="pl-2">
                      1.1 NEXIIOT Platform license option packages
                    </span>
                    <br />
                    <span className="pl-4">- Startup package</span>
                    <br />
                    <span className="pl-4">- Growth package</span>
                    <br />
                    <span className="pl-4">- Business package</span>
                    <br />
                    <span className="pl-2">1.2 Private NEXIIOT Platform</span>
                    <br />
                    <span className="pl-4">
                      - This package is using dedicated resources, users or
                      customers have to provide a server to run the platform.
                    </span>
                    <br />
                    <span className="pl-2">1.3 Custom plan</span>
                    <br />
                    <span className="pl-4">
                      - A customized plan that accommodates your unique needs.
                    </span>
                    <br />
                    <br />
                    <span className="font-bold">2. Billing</span>
                    <br />
                    <span className="pl-2">
                      - Pay before using, no need for a credit card to
                      subscribe. Users can choose payment methods such as a
                      one-time payment or monthly payment. We request users to
                      pay via bank account payment.
                    </span>
                    <br />
                    <br />
                    <span className="font-bold">
                      3. Renewal, Add-On, Cancel
                    </span>
                    <br />
                    <span className="pl-2">
                      - Contact us by email at contact@nexpie.com for
                      confirmation to renew, add-on, or cancel your account.
                      When receiving your email, we shall process the service
                      that you request and notice you.
                    </span>
                  </dd>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Temporary Suspensions
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                    <span className="font-bold">
                      End of rate-limit of your account.
                    </span>{" "}
                    Overdue payment. We may notice and inform you before
                    temporarily suspending your account. If the rate-limit of
                    your account ends, you can do the Add-on service. However,
                    if your account is overdue for payment, we advise you to pay
                    to renew the service.
                  </dd>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Termination
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                    We may terminate or suspend access to our Service
                    immediately, without prior notice or liability, for any
                    reason whatsoever, including without limitation if you
                    breach the Terms. All provisions of the Terms which by their
                    nature should survive termination shall survive termination,
                    including, without limitation, ownership provisions,
                    warranty disclaimers, indemnity, and limitations of
                    liability. We may terminate or suspend your account
                    immediately, without prior notice or liability, for any
                    reason whatsoever, including without limitation if you
                    breach the Terms. Upon termination, your right to use the
                    Service will immediately cease. If you wish to terminate
                    your account, you may simply discontinue using the Service.
                    All provisions of the Terms which by their nature should
                    survive termination shall survive termination, including,
                    without limitation, ownership provisions, warranty
                    disclaimers, indemnity, and limitations of liability.
                  </dd>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Intellectual Right
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                    You acknowledge and agree that NEXPIE (or NEXPIE's
                    licensors) own all legal rights, title, and interest in and
                    to the Service, including any intellectual property rights
                    which subsist in the Service (whether those rights happen to
                    be registered or not, and wherever in the world those rights
                    may exist). Unless you have agreed otherwise in writing with
                    NEXPIE, nothing in the Terms gives you a right to use any of
                    NEXPIE's trade names, trademarks, service marks, logos,
                    domain names, and other distinctive brand features. NEXPIE
                    acknowledges and agrees that it obtains no right, title, or
                    interest from you (or your licensors) under these Terms in
                    or to any User Content or the Application that you create,
                    submit, post, transmit or display on, or through, the
                    Service, including any intellectual property rights which
                    subsist in that User Content and Application (whether those
                    rights happen to be registered or not, and wherever in the
                    world those rights may exist). Unless you have agreed
                    otherwise in writing with NEXPIE, you agree that you are
                    responsible for protecting and enforcing those rights and
                    that NEXPIE has no obligation to do so on your behalf.
                  </dd>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Legitimate Use of the Service
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                    The Service is made available to you for your legitimate
                    personal and commercial use. You must have a valid account
                    to use the Service. Different Service capabilities may be
                    available to your account and varying rate limits and/or
                    usage limits may be applied to your account based on the
                    package of Service chosen and payments made. You may not use
                    any means, including the registration and use of multiple
                    accounts, to attempt to circumvent these limits. You may
                    create connected devices or write software applications
                    (each an “Application”) that interface with the Service. The
                    Service allows you to authorize Applications used by third
                    parties to access the Service on your behalf. You may not
                    allow any unauthorized third party to access the Services
                    for any purpose whatsoever. You and any Applications that
                    you may build, distribute, or otherwise create may make
                    network calls or requests to the Service at any time that
                    the Service is available, provided that those requests do
                    not violate the rate-limit of your account (you may pay to
                    add-on the rate-limit service) and do not violate the NEXPIE
                    Privacy Policy and these Terms. Furthermore, you may not use
                    the Service in any manner that either directly or indirectly
                    violates any laws or intellectual property rights. You may
                    not (and you may not permit anyone else to) (a) access (or
                    attempt to access) the administrative interface of the
                    Service by any means other than through the interface that
                    is provided by us in connection with the Service, unless you
                    have been specifically allowed to do so in a separate
                    agreement with us; (b) attempt to disable or circumvent any
                    security mechanisms used by the Service or any Application;
                    (c) engage in any activity that interferes with or disrupts
                    the Service (or the servers and networks which are connected
                    to the Service), including but not limited to spamming
                    users, harvesting usernames and passwords, performing
                    unauthorized scans of machines or ports or creating DoS
                    attacks; or (d) upload or otherwise process any malicious
                    content to or through the Service.
                  </dd>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Limitations of Liability
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                    <span className="font-bold">
                      SUBJECT TO THE EXCLUSION OF WARRANTIES ABOVE, YOU
                      EXPRESSLY UNDERSTAND AND AGREE THAT NEXPIE AND ITS
                      LICENSORS SHALL NOT BE LIABLE TO YOU FOR ANY DIRECT,
                      INDIRECT, INCIDENTAL, SPECIAL CONSEQUENTIAL OR EXEMPLARY
                      DAMAGES WHICH MAY BE INCURRED BY YOU, HOWEVER CAUSED AND
                      UNDER ANY THEORY OF LIABILITY. THIS SHALL INCLUDE, BUT NOT
                      BE LIMITED TO, ANY LOSS OF PROFIT (WHETHER INCURRED
                      DIRECTLY OR INDIRECTLY), ANY LOSS OF GOODWILL OR BUSINESS
                      REPUTATION, ANY LOSS OF DATA SUFFERED, COST OF PROCUREMENT
                      OF SUBSTITUTE GOODS OR SERVICES, OR OTHER INTANGIBLE LOSS.
                    </span>
                  </dd>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Modifications
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                    NEXPIE reserves the right to modify these Terms and
                    Conditions at any time, in Our discretion. We will post
                    modifications of the Terms and Conditions on the Site and
                    other places We deem appropriate. If We make material
                    changes to these Terms and Conditions, We will notify you
                    here, by email, or through a notice on Our Site’s home page.
                    Your continued use or access of the Site or Content
                    following any changes to these Terms and Conditions shall
                    constitute Your acceptance of the changes and the revised
                    Terms.
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TermsOfUse;
