import { ArrowDownIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const baseButtonClass =
  "inline-flex items-center justify-center gap-x-1.5 px-3 py-2 font-normal";

const LinkWrapper = ({ link, external, children }) => {
  const linkProps = external
    ? { target: "_blank", rel: "noopener noreferrer" }
    : {};
  return (
    <Link to={link} {...linkProps}>
      {children}
    </Link>
  );
};

export const GhostButton = ({ children, borderLess, link, ...rest }) => {
  const buttonClass = `${baseButtonClass} w-full text-sm text-gray-900 bg-white ${
    borderLess ? "ring-0" : "ring-1 shadow-sm"
  } ring-inset ring-gray-300 hover:bg-gray-50`;
  return (
    <LinkWrapper link={link}>
      <button {...rest} className={buttonClass}>
        {children}
      </button>
    </LinkWrapper>
  );
};

export const PrimaryButton = ({
  children,
  className,
  arrow,
  link,
  external,
  ...rest
}) => {
  const buttonClass = `bg-blue-900 border-2 border-blue-900 ${baseButtonClass} text-white hover:bg-primaryHover ${className}`;
  return (
    <LinkWrapper link={link} external={external}>
      <button {...rest} className={buttonClass}>
        {children}
        {arrow && <ArrowRightIcon className="h-5 w-5 text-white" />}
      </button>
    </LinkWrapper>
  );
};

export const PrimaryOutlineButton = ({
  children,
  borderLess,
  className,
  color = "primary",
  link,
  external,
  ...rest
}) => {
  const buttonClass = `bg-transparent ${baseButtonClass} text-${color} ${
    !borderLess ? `border-2 border-${color}` : ""
  } ${className} hover:bg-primaryHover hover:text-white`;
  return (
    <LinkWrapper link={link} external={external}>
      <button {...rest} className={buttonClass}>
        {children}
      </button>
    </LinkWrapper>
  );
};

export const ShadowButton = ({
  children,
  className,
  link,
  icon = <ArrowDownIcon className="h-5 w-5" />,
  ...rest
}) => {
  const buttonClass = `shadow rounded-lg py-2 px-4 hover:scale-[1.1] ${baseButtonClass} ${className}`;
  return (
    <LinkWrapper link={link}>
      <button {...rest} className={buttonClass}>
        {children}
        {icon}
      </button>
    </LinkWrapper>
  );
};

export const GlowButton = ({ link, children, className }) => {
  return (
    <LinkWrapper link={link} external>
      <button className={`${className} btn-101`}>
        {children}
        <svg>
          <defs>
            <filter id="glow">
              <feGaussianBlur
                result="coloredBlur"
                stdDeviation="5"
              ></feGaussianBlur>
              <feMerge>
                <feMergeNode in="coloredBlur"></feMergeNode>
                <feMergeNode in="coloredBlur"></feMergeNode>
                <feMergeNode in="coloredBlur"></feMergeNode>
                <feMergeNode in="SourceGraphic"></feMergeNode>
              </feMerge>
            </filter>
          </defs>
          <rect />
        </svg>
      </button>
    </LinkWrapper>
  );
};
