import { useEffect, useState } from "react";

const useReadingTimeFormatter = (readingTime) => {
  const [formattedReadingTime, setFormattedReadingTime] = useState("");

  useEffect(() => {
    const readingTimeParts = readingTime?.split(" ");
    if (
      readingTimeParts?.length === 3 &&
      readingTimeParts[1] === "min" &&
      readingTimeParts[2] === "read"
    ) {
      const minutes = readingTimeParts[0];
      const formattedTime = `${minutes} minute${minutes > 1 ? "s" : ""} read`;
      setFormattedReadingTime(formattedTime);
    } else {
      setFormattedReadingTime(readingTime);
    }
  }, [readingTime]);

  return formattedReadingTime;
};

export default useReadingTimeFormatter;
