import React, { useRef } from "react";
import logo from "assets/nexiiot-black.svg";
import { Link } from "react-router-dom";
import useUrlFormat from "hooks/useUrlFormat";
import "./customStyle.css";

export const NewsCard = ({ image, item }) => {
  const handleImageError = (event) => {
    event.target.onerror = null; // to prevent infinite loop
    event.target.src = logo; // set the src to the imported logo image
  };
  const urlTitle = useUrlFormat(item.title);

  const cardRef = useRef();
  return (
    <Link
      to={`/blog/${urlTitle}`}
      state={{ id: item.id }}
      ref={cardRef}
      className="block max-w-lg md:max-w-xs  bg-white border border-gray-200 rounded-xl cursor-pointer min-h-fit news-card overflow-hidden"
    >
      <div className={`image-wrapper mx-auto min-w-full overflow-hidden`}>
        <img
          className={`w-full aspect-video rounded-t-xl ${
            item.feature_image?.url ? "object-cover" : "object-contain"
          }`}
          src={image}
          onError={handleImageError}
          alt={item.title}
        />
      </div>
      <div className="p-5">
        {/* type - ert */}
        <div className="flex items-center justify-between uppercase text-gray-500 text-xs mb-2">
          <span>{item.blog_type?.name}</span>
          <span>{item.ERT}</span>
        </div>
        {/* name */}
        <p className="font-bold text-2xl">{item.title}</p>
        {/* description */}
        <p className="line-clamp-3 text-gray-500">{item.description}</p>
      </div>
    </Link>
  );
};
