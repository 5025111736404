import { GlowButton } from "components/Button";
import "../customStyle.css";
import group_mobile from "../../assets/hero/group-mobile.svg";
import group_desktop from "../../assets/hero/group-desktop.svg";
import { useQuery } from "@apollo/client";
import { GET_HEADERS } from "utils/apollo/graphql/home";
import Loading from "components/Loading";
import React from "react";
import { Customer } from "./customer";
import defaultData from "../../data/defaultData.json";
import { useTranslation } from "react-i18next";

export const Hero = () => {
  const { loading, data } = useQuery(GET_HEADERS);
  const { t } = useTranslation();

  if (loading) {
    return <Loading />;
  }

  const content = data?.home || defaultData.home;

  const highlightedHeader = content.header.split(" ").map((word, index) => {
    if (word.toLowerCase() === "nexiiot") {
      return (
        <span style={{ color: "#ffc414" }} key={index}>
          {word + " "}
        </span>
      );
    }
    return `${word} `;
  });

  return (
    <div className="hero-bg relative">
      <div className="max-w-screen-xl mx-auto grid grid-cols-1 lg:grid-cols-5 justify-between min-h-screen">
        <div className="flex flex-col justify-center text-white px-4 lg:ps-4 my-auto lg:col-span-3">
          <p className="text-2xl md:text-4xl font-bold text-center lg:text-start max-w-full mt-20 lg:mt-0 lg:pe-3">
            {content.sub_header}
          </p>
          <p className="text-4xl md:text-6xl font-medium mt-4 text-center lg:text-start max-w-full lg:pe-3">
            {highlightedHeader}
          </p>
          <p className="mt-6 max-w-prose text-center mx-auto lg:mx-0 lg:text-start lg:pe-3">
            {content.header_description}
          </p>
          <div className="w-full">
            <GlowButton
              className="mt-8 mx-auto lg:mx-0"
              link="https://auth.nexiiot.io/signup"
            >
              {t("getStart")}
            </GlowButton>
          </div>
        </div>
        <img
          alt="desktop"
          src={group_desktop}
          className="hidden lg:flex absolute bg-transparent max-h-[83vh] max-w-[780px] w-[45%] lg:inset-y-[calc(40%_-_20vw)] right-0 opacity-100"
        />
        <div className="w-full flex lg:hidden flex-col max-w-screen-xl mx-auto">
          <img
            key="tmp"
            alt="temperature"
            src={group_mobile}
            className="bg-transparent w-full my-auto mx-auto"
          />
        </div>
      </div>
      <Customer />
    </div>
  );
};
