import React from "react";
import { Profile } from "./Profile";
import logo_white from "assets/nexiiot-white.svg";
import { DropdownNav } from "components/Dropdown";
import "../customStyle.css";
import { useLocation, useParams } from "react-router-dom";
import ExternalLinkIcon from "assets/home/externalLinkIcon";
import { useTranslation } from "react-i18next";

const menuList = [
  { label: "home", link: "/", type: "link" },
  { label: "documentation", link: "https://docs.nexiiot.io/", type: "link" },
  { label: "blog", link: "/blog", type: "link" },
  { label: "pricing", link: "/pricing", type: "link" },
];

export const Header = (props) => {
  const location = useLocation();
  const { lang } = useParams();
  const isHomePage =
    location.pathname === `/${lang}` || location.pathname === `/${lang}/`;
  const { t } = useTranslation();

  const isExternalLink = (link) => {
    return !/^((?!localhost|nexiiot.io).)*$/.test(link);
  };

  return (
    <header
      className={`fixed top-0 left-0 w-screen z-50 py-4 text-warm_white  ${
        isHomePage
          ? props.isTransparent
            ? "bg-transparent backdrop-blur-lg "
            : "bg-primaryHover"
          : "bg-primaryHover"
      }`}
    >
      <div className="max-w-screen-xl pr-4 xl:pr-0 mx-auto flex items-center justify-between">
        {/* logo */}
        <a href={`/${lang}`}>
          <img alt="logo" src={logo_white} className="w-44" />
        </a>
        {/* Menu */}
        <div className="hidden md:flex gap-1">
          {menuList.map((menu, index) => (
            <a
              href={
                isExternalLink(menu.link) ? menu.link : `/${lang}${menu.link}`
              }
              key={`header_menu_${index}`}
              className={`flex items-center gap-2 rounded-lg my-auto px-5 font-normal align-text-middle ${
                isExternalLink(menu.link) ? "external-link" : ""
              }`}
              target={isExternalLink(menu.link) ? "_blank" : ""}
              rel={isExternalLink(menu.link) ? "noopener noreferrer" : ""}
            >
              <span className="menu-label">{t(menu.label)}</span>
              {isExternalLink(menu.link) && <ExternalLinkIcon size={16} />}
            </a>
          ))}
        </div>
        <div className="hidden md:flex">
          <Profile />
        </div>
        {/* right section */}
        <div className="bg-transparent flex md:hidden text-primary">
          <DropdownNav
            options={menuList.map((menu) => ({
              ...menu,
              link: isExternalLink(menu.link)
                ? menu.link
                : `/${lang}${menu.link}`,
            }))}
          />
        </div>
      </div>
    </header>
  );
};
