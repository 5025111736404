import { gql } from "@apollo/client";

const GET_FAQ = gql`
  query faq($locale: String) {
    faq(locale: $locale) {
      question {
        id
        question
        answer
      }
    }
  }
`;

export { GET_FAQ };
