import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { GET_CUSTOMERS } from "utils/apollo/graphql/home";

const CustomerCard = ({ customer }) => (
  <Link to={customer.url} target="_blank">
    <li className="bg-primary/50 px-10 py-5 rounded-xl">
      <img
        src={`${process.env.REACT_APP_STRAPI_URL}${customer.logo.url}`}
        alt={`${customer.name}_logo`}
        className="filter grayscale brightness-140"
        style={{ height: 40 }}
      />
    </li>
  </Link>
);

const CustomerList = ({ customers }) => (
  <div className="w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]">
    <ul className="flex items-center justify-center md:justify-start [&_li]:mx-2 [&_img]:max-w-none animate-infinite-scroll">
      {customers.map((customer, index) => (
        <CustomerCard
          key={`customer_${customer.name}_${index}`}
          customer={customer}
        />
      ))}
    </ul>
  </div>
);

export const Customer = () => {
  const { loading, data } = useQuery(GET_CUSTOMERS);

  if (loading) {
    return <></>;
  }

  const customers = data?.customer?.customerCard ?? [];

  if (customers.length === 0) {
    return <div className="py-20" />;
  }

  return (
    <div className="py-20 text-center text-lg text-white">
      <h1 className="mb-6 text-lg lg:text-2xl">
        {data?.customer?.description || "Our beloved customer"}
      </h1>
      <div className="max-w-screen-xl mx-auto">
        <CustomerList customers={customers} />
      </div>
    </div>
  );
};
