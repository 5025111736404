import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import preview from "../../assets/home/preview-portal.mp4";
import { GET_PREVIEW } from "utils/apollo/graphql/home";

export const Preview = () => {
  const { loading, error, data } = useQuery(GET_PREVIEW);
  const [defaultVideoLoaded, setDefaultVideoLoaded] = useState(false);

  useEffect(() => {
    const video = document.getElementById("preview");

    if (!loading && !error && data?.preview?.video?.url) {
      video.src = `${process.env.REACT_APP_STRAPI_URL}${data.preview.video.url}`;
      video.load();
      video.play();
    } else {
      // Fallback to default video
      video.src = preview;
      video.load();
      video.play();
      setDefaultVideoLoaded(true);
    }
  }, [loading, error, data]);

  return (
    <div className="max-w-screen-lg mx-10 lg:mx-auto py-24">
      <p className="text-2xl md:text-4xl font-medium text-primary text-center">
        {data?.preview?.title ||
          (defaultVideoLoaded && (
            <>
              Simplify industrial IoT,
              <br /> amplify business efficiency.
            </>
          ))}
      </p>

      <div className="mt-10 relative">
        <video
          autoPlay
          muted
          playsInline
          loop
          id="preview"
          className="rounded-2xl aspect-video"
        >
          <source src={preview} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};
