import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "index";
import {
  GET_BLOG_LIST,
  GET_BLOG_TYPES,
  SEARCH_BLOGS_QUERY,
} from "utils/apollo/graphql/blog";

export const loadBlogList = createAsyncThunk(
  "blogs/loadBlogList",
  async ({ categoryName, limit, start }) => {
    const { data } = await client.query({
      query: GET_BLOG_LIST,
      variables: {
        category: categoryName,
        limit,
        start,
      },
    });

    const countRes = await client.query({
      query: GET_BLOG_LIST,
      variables: {
        category: categoryName,
      },
    });

    const count = countRes?.data?.blogs.length;

    return { blogs: data.blogs, count };
  }
);

export const loadBlogType = createAsyncThunk("blogs/loadBlogType", async () => {
  const { data } = await client.query({
    query: GET_BLOG_TYPES,
  });

  return data.blogTypes;
});

export const searchBlogs = createAsyncThunk(
  "blogs/searchBlogs",
  async (query) => {
    const { data } = await client.query({
      query: SEARCH_BLOGS_QUERY,
      variables: {
        query,
      },
    });

    return data.blogs;
  }
);

export const blogSlice = createSlice({
  name: "blogs",
  initialState: {
    blogs: [],
    count: 0,
    loading: false,
    error: null,
    blog_type: [],
    typeLoading: false,
    typeError: null,
    searchResults: [],
    searchLoading: false,
  },

  reducers: {
    clearSearchResults: (state) => {
      state.searchResults = [];
    },
  },

  extraReducers: (builder) => {
    builder
      // Case for loadBlogList.pending
      .addCase(loadBlogList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      // Case for loadBlogList.fulfilled
      .addCase(loadBlogList.fulfilled, (state, action) => {
        state.blogs = action.payload.blogs;
        state.count = action.payload.count;
        state.loading = false;
      })
      // Case for loadBlogList.rejected
      .addCase(loadBlogList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Case for loadBlogType.pending
      .addCase(loadBlogType.pending, (state) => {
        state.typeLoading = true;
        state.typeError = null;
      })
      // Case for loadBlogType.fulfilled
      .addCase(loadBlogType.fulfilled, (state, action) => {
        state.blog_type = action.payload;
        state.typeLoading = false;
      })
      // Case for loadBlogType.rejected
      .addCase(loadBlogType.rejected, (state, action) => {
        state.typeLoading = false;
        state.typeError = action.error.message;
      })
      // Case for searchBlogs.pending
      .addCase(searchBlogs.pending, (state) => {
        state.searchLoading = true;
        state.searchResults = [];
      })
      // Case for searchBlogs.fulfilled
      .addCase(searchBlogs.fulfilled, (state, action) => {
        state.searchResults = action.payload;
        state.searchLoading = false;
      })
      // Case for searchBlogs.rejected
      .addCase(searchBlogs.rejected, (state, action) => {
        state.searchLoading = false;
        state.error = action.error.message;
      });
  },
});

// Export the slice and its actions
export const { setBlogType, clearSearchResults } = blogSlice.actions;
export default blogSlice.reducer;
