import React, { useEffect, useRef } from "react";
import logo from "assets/nexiiot-black.svg";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import parse, { domToReact } from "html-react-parser";

gsap.registerPlugin(ScrollTrigger);

// Custom processing function to add styles and icon
const addIconToListItems = (node) => {
  if (node.name === "li" && node.type === "tag") {
    return (
      <li className="flex items-start space-x-1.5">
        <div className="min-w-5 min-h-5">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.99935 18.3334C14.5827 18.3334 18.3327 14.5834 18.3327 10C18.3327 5.41669 14.5827 1.66669 9.99935 1.66669C5.41602 1.66669 1.66602 5.41669 1.66602 10C1.66602 14.5834 5.41602 18.3334 9.99935 18.3334Z"
              stroke="#46CF78"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M6.45898 10L8.81732 12.3583L13.5423 7.64166"
              stroke="#46CF78"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </div>
        <span className="">{domToReact(node.children)}</span>
      </li>
    );
  }
  if (node.name === "ul" && node.type === "tag") {
    return (
      <ul className="space-y-4 lg:space-y-3 mt-4">
        {domToReact(node.children, { replace: addIconToListItems })}
      </ul>
    );
  }
};

const useGsapAnimation = (ref) => {
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ref.current,
        start: "top 90%",
        end: "center 90%",
        scrub: true,
      },
    });

    tl.fromTo(ref.current, { y: 150 }, { y: 0, ease: "power2.out" });

    return () => {
      tl.kill();
    };
  }, [ref]);
};

const FeatureCard = React.memo(
  ({
    title,
    subtitle,
    description,
    image,
    order,
    children,
    cta_title,
    cta_link,
    dark,
  }) => {
    const handleImageError = (event) => {
      event.target.onerror = null;
      event.target.src = logo;
    };

    const cardRef = useRef();
    useGsapAnimation(cardRef);

    return (
      <div
        className="gradient-bg rounded-lg px-4 sm:px-6 md:px-8 pb-8 border border-[#deebff] text-neutral-dark mb-6"
        ref={cardRef}
      >
        <div
          className={`rounded-lg rounded-t-none border border-t-0 border-[#deebff] divide-y lg:divide-y-0 ${
            order ? "" : "lg:divide-x"
          } divide-[#deebff] grid lg:grid-cols-2 min-h-[400px]`}
        >
          <div
            className={`p-4 sm:p-6 lg:p-8 pt-0 lg:pb-14 mt-4 md:mt-0 ${
              order ? "lg:order-last lg:border-l border-[#deebff]" : ""
            }`}
          >
            <h4 className="text-primary font-medium text-2xl">{title}</h4>
            <div className="mt-4 mb-8 text-balance feature-style">
              {parse(description, { replace: addIconToListItems })}
            </div>
            {cta_title && (
              <a
                href={cta_link}
                rel="noreferrer noopener"
                target="_blank"
                className="w-fit text-md md:text-xl text-link hover:text-primary grid grid-cols-2 gap-2 hover:gap-5 duration-300"
              >
                <span className="w-fit">{cta_title}</span>
                <ArrowRightIcon className="w-[20px] inline my-auto" />
              </a>
            )}
          </div>
          <div
            className={`relative ${
              order
                ? "pr-4 sm:pr-6 lg:pr-8 lg:border-l-0"
                : "pl-4 sm:pl-6 lg:pl-8"
            } pt-6 lg:pt-8`}
          >
            <div className="relative h-64 sm:h-96 lg:h-full w-full">
              <img
                alt={title}
                loading="lazy"
                decoding="async"
                className={`ml-auto object-contain aspect-video overflow-hidden ${
                  order
                    ? "rounded-tr-xl rounded-bl-xl"
                    : "rounded-tl-xl rounded-br-xl"
                }  object-contain object-center`}
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  minWidth: "240px", // Adjust this value as needed
                  minHeight: "180px", // Adjust this value as needed
                  left: 0,
                  top: 0,
                  right: 0,
                  bottom: 0,
                  color: "transparent",
                }}
                src={image}
                onError={handleImageError}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default FeatureCard;
