import React, { memo, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { gsap } from "gsap";
import * as Parser from "rss-parser";
import { useTranslation } from "react-i18next";

import { BlogCard } from "components/BlogCard";
import { Layout } from "components/layout";
import Loading from "components/Loading";
import { ArrowDownIcon } from "@heroicons/react/24/outline";
import { ShadowButton } from "components/Button";
import NotFound from "components/NotFound";
import SearchModal from "components/SearchModal";

import { loadBlogList, loadBlogType } from "redux/reducer/blogReducer";
import searchIcon from "assets/search.svg";
import { MediumIcon } from "assets/social/medium";

const BLOGS_PER_PAGE = 6;

const BlogList = () => {
  const [start, setStart] = useState(0);
  const [lastIndex, setLastIndex] = useState(-1);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [articles, setArticles] = useState([]);
  const [articlesError, setArticlesError] = useState(null);

  const { categoryName, lang } = useParams();
  const { blogs, count, loading, blog_type, error } = useSelector(
    (state) => state.blogs
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const blogCardsRef = useRef([]);

  useEffect(() => {
    if (!blog_type.length) dispatch(loadBlogType());
    dispatch(
      loadBlogList({
        categoryName,
        limit: start === 0 ? 5 : BLOGS_PER_PAGE,
        start,
      })
    );
  }, [blog_type, categoryName, dispatch, start]);

  useEffect(() => {
    if (error) {
      setLastIndex(-1);
    }
  }, [blogs, error]);

  useEffect(() => {
    if (blogs.length) {
      const startIndex = lastIndex + 1;
      const endIndex = blogs.length - 1;

      gsap.fromTo(
        blogCardsRef.current.slice(startIndex, endIndex + 1),
        { opacity: 0, scale: 0 },
        { opacity: 1, scale: 1, duration: 0.4, stagger: 0.2 }
      );
      setLastIndex(endIndex);
    }
  }, [blogs, lastIndex]);

  useEffect(() => {
    async function fetchArticles() {
      const parser = new Parser();
      try {
        const response = await fetch(
          `https://api.allorigins.win/get?url=${encodeURIComponent(
            "https://medium.com/feed/nexpie-co-ltd"
          )}`
        );
        const data = await response.json();
        const rss = await parser.parseString(data.contents);
        const articles = rss.items.map((item) => ({
          ...item,
          image: item["content:encoded"].match(/<img[^>]+src="([^">]+)"/)?.[1],
          pubDate: new Date(item.pubDate),
        }));

        // Sort articles by pubDate in descending order
        articles.sort((a, b) => b.pubDate - a.pubDate);

        setArticles(articles);
      } catch (err) {
        setArticlesError("Failed to fetch articles. Please try again later.");
      }
    }
    fetchArticles();
  }, []);

  const loadMore = () => {
    const newStart = start === 0 ? 5 : start + BLOGS_PER_PAGE;
    setStart(newStart);
    dispatch(
      loadBlogList({ categoryName, limit: BLOGS_PER_PAGE, start: newStart })
    );
  };

  const renderBlogCards = () => {
    return blogs.map((blog, index) => (
      <div
        ref={(el) => (blogCardsRef.current[index] = el)}
        key={`blog_${index}`}
        className={`col-span-6 sm:col-span-3 ${
          index < 2 ? "md:col-span-3" : "md:col-span-2"
        } border rounded-xl hover:border-gray-300 cursor-pointer overflow-hidden`}
      >
        <BlogCard item={blog} index={index} />
      </div>
    ));
  };

  const renderArticleCards = () => {
    return articles.map((article, index) => (
      <div
        ref={(el) => (blogCardsRef.current[index + blogs.length] = el)}
        key={`medium_${index + blogs.length}`}
        className={`col-span-6 sm:col-span-3 ${
          index + blogs.length < 2 ? "md:col-span-3" : "md:col-span-2"
        } border rounded-xl hover:border-gray-300 cursor-pointer overflow-hidden`}
      >
        <BlogCard item={article} index={index} isMedium />
      </div>
    ));
  };

  return (
    <Layout>
      <div className="mt-20 pt-5 h-full max-w-screen-lg mx-auto min-h-screen px-4">
        <div className="text-6xl font-medium max-w-screen-md mx-auto mt-5 mb-12 text-center">
          Take your IoT journey to new heights. 🚀✨
        </div>
        <div className="block md:flex items-center justify-center gap-2 capitalize my-5 pb-2 overflow-auto">
          {blog_type.length !== 0 && (
            <Link
              to={`/${lang}/blog`}
              className={`py-1 px-3 rounded-full ${
                !categoryName
                  ? "bg-primary text-white"
                  : "border border-gray-200"
              }`}
            >
              <span>{t("recent")}</span>
            </Link>
          )}
          {blog_type.map((type, index) => (
            <Link
              to={`/${lang}/blog/categories/${type.name}`}
              key={`blog_type_${index}`}
              className={`py-1 px-3 rounded-full ${
                categoryName === type.name
                  ? "bg-primary text-white"
                  : "border border-gray-200"
              }`}
            >
              <span>{type.name}</span>
            </Link>
          ))}
          <Link
            to={`/${lang}/blog/categories/medium`}
            className={`py-1 px-3 rounded-full ${
              categoryName === "medium"
                ? "bg-primary text-white"
                : "border border-gray-200"
            }`}
          >
            <span className="flex items-center gap-1">
              <MediumIcon
                className={
                  categoryName === "medium" ? "font-white" : "font-black"
                }
                size={12}
              />{" "}
              Medium
            </span>
          </Link>
          <button
            onClick={() => setShowSearchModal(true)}
            className="text-white py-1 px-3 rounded-full border border-gray-200"
          >
            <img
              src={searchIcon}
              alt="Search Icon"
              className="w-5 h-5 inline"
            />
          </button>
        </div>

        {(error || !blogs) && <NotFound title="Blogs" />}
        {((blogs && blogs.length > 0) ||
          ((categoryName === "medium" || !categoryName) &&
            articles.length > 0)) &&
        !loading &&
        !error ? (
          <div className="grid grid-cols-6 gap-4">
            {renderBlogCards()}
            {(categoryName === "medium" || !categoryName) &&
              renderArticleCards()}
          </div>
        ) : !loading && !error && (categoryName !== "medium" || !articles) ? (
          <div className="text-center mt-20">{t("noBlogsAvailable")}</div>
        ) : null}
        {loading && <Loading />}
        {blogs.length > 0 && blogs.length < count && (
          <div className="w-full text-center mt-5">
            <ShadowButton
              onClick={loadMore}
              icon={<ArrowDownIcon className="h-5 w-5 animate-bounce" />}
            >
              {t("loadMore")}
            </ShadowButton>
          </div>
        )}
        <SearchModal
          articles={articles}
          isOpen={showSearchModal}
          onClose={() => setShowSearchModal(false)}
        />
      </div>
    </Layout>
  );
};

export default memo(BlogList);
