import { useQuery } from "@apollo/client";
import { GET_APPLICATION } from "utils/apollo/graphql/home";
import appImage from "../../assets/application/app-nexiiot.png";
import AppButton from "assets/application/appButton";
import defaultData from "../../data/defaultData.json";
import QRCodeScanner from "components/QRCodeScanner";
import appLogo from "../../assets/application/app-logo.png";
import { useTranslation } from "react-i18next";

export const Application = () => {
  const { loading, data } = useQuery(GET_APPLICATION);
  const { t } = useTranslation();

  if (loading) return null;

  const applications =
    data?.application?.store || defaultData.application.store;

  return (
    <section className="max-w-screen-xl my-20 mx-10 xl:mx-auto app-bg rounded-xl pt-5 px-5 md:p-20 grid  grid-cols-1 grid-rows-2 md:grid-cols-2 md:grid-rows-1 gap-6 overflow-hidden">
      {/* Left */}
      <div className="md:px-[7%] w-fit mx-auto h-fit">
        <div className="flex items-center w-full mx-auto">
          <img
            src={appLogo}
            className="w-20 md:w-24 lg:w-28 me-[6%]"
            alt="nexiiot_logo"
          />
          <div>
            <h1 className="text-xl md:text-4xl lg:text-6xl font-medium text-white tracking-wide">
              NEXIIOT
            </h1>
            <h1 className="text-lg md:text-2xl lg:text-4xl text-white tracking-wider">
              Application
            </h1>
          </div>
        </div>

        <p className="text-md md:text-xl lg:text-2xl font-medium text-white mt-8 w-full text-center">
          <span className="text-emerald-300">{t("downloadApp")}</span> {t("or")}
          <span className="text-emerald-300"> {t("scanNow")}</span>
        </p>

        <div className="flex items-center gap-4 mt-4 mx-auto">
          <div className="grid gap-4 w-full">
            {applications.map((store, index) => (
              <AppButton app={store} key={index} />
            ))}
          </div>
          <div className="hidden md:flex w-[70%] mx-auto">
            <QRCodeScanner />
          </div>
        </div>
      </div>

      {/* Right */}
      <div className="lg:relative w-full my-auto md:my-0">
        <img
          src={appImage}
          alt="Application"
          className="lg:absolute -bottom-[70%] mx-auto w-[220px] my-auto lg:my-0 mb-6 md:pb-0 lg:min-w-[380px]"
        />
      </div>
    </section>
  );
};
