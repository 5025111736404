import { icons } from "assets/social";
import logo from "assets/nexiiot-icon.svg";
import { useQuery } from "@apollo/client";
import { GET_FOOTER_CONTENT } from "utils/apollo/graphql/layout";
import defaultData from "../../data/defaultData.json";
import { PhoneIcon, EnvelopeOpenIcon } from "@heroicons/react/20/solid";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { loading, data } = useQuery(GET_FOOTER_CONTENT);
  const { t } = useTranslation();

  if (loading) {
    return <div>{t("loading")}...</div>;
  }

  // Extracting data or fallback to defaultData
  const contact = data?.contact || defaultData.footer.contact;
  const socialMedia =
    data?.socialMedia?.social || defaultData.footer.socialMedia.social;

  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-primary w-full solid" id="footer">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex justify-center lg:justify-between">
          <div className="grid grid-cols-1 gap-8 lg:gap-6 lg:grid-cols-4">
            <LogoSection />
            <AddressSection address={contact?.address} />
            <ContactDetailsSection
              phone={contact?.phone_number}
              email={contact?.email}
            />
            <SocialMediaSection socialMedia={socialMedia} />
          </div>
        </div>
        <FooterBottom currentYear={currentYear} />
      </div>
    </footer>
  );
};

const LogoSection = () => (
  <div className="mb-6 lg:mb-0 mx-auto">
    <a href="/" className="flex items-center">
      <img src={logo} className="h-8 px-3 rounded-full" alt="nexiiot_logo" />
      <span className="self-center text-3xl font-medium whitespace-nowrap text-white">
        NEXIIOT
      </span>
    </a>
  </div>
);

const AddressSection = ({ address }) => {
  const { t } = useTranslation();
  return (
    <div>
      <h2 className="mb-6 text-md font-medium text-white">{t("address")}</h2>
      <div className="text-warm_white font-light">
        <p className="mb-4">{address}</p>
      </div>
    </div>
  );
};

const ContactDetailsSection = ({ phone, email }) => {
  const { t } = useTranslation();
  return (
    <div>
      <h2 className="mb-6 text-md font-medium text-white">{t("contact")}</h2>
      <div className="text-warm_white font-light">
        {phone && (
          <p className="mb-4">
            <PhoneIcon className="w-5 h-5 inline me-2" />
            {phone}
          </p>
        )}
        {email && (
          <p className="mb-4">
            <EnvelopeOpenIcon className="w-5 h-5 inline me-2" />
            {email}
          </p>
        )}
      </div>
    </div>
  );
};

const SocialMediaSection = ({ socialMedia }) => {
  const { t } = useTranslation();
  return (
    <div>
      <h2 className="mb-6 text-md font-medium text-white">
        {t("socialMedia")}
      </h2>
      <div className="w-fit mt-4 grid grid-cols-5 sm:grid-cols-auto lg:grid-cols-4 gap-4 text-gray-400 font-normal">
        {socialMedia
          ?.filter((social) => social?.link && social?.link !== "")
          .map((social, index) => (
            <a
              href={social.link}
              className="text-warm_white w-fit"
              key={`social_${index}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="rounded-full bg-white/10 p-3 hover:bg-primaryHover">
                {icons[social.name]}
              </div>
            </a>
          ))}
      </div>
    </div>
  );
};

const FooterBottom = ({ currentYear }) => {
  const { lang } = useParams();
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-center mt-10 font-light">
      <span className="text-warm_white text-sm">Copyright © {currentYear}</span>
      <hr className="bg-white w-[1px] h-5 mx-2" />
      <Link
        to="https://nexpie.com/privacypolicy.pdf"
        target="_blank"
        rel="noopener noreferrer"
        className="text-warm_white text-sm"
      >
        {t("privacyPolicy")}
      </Link>
      <hr className="bg-white w-[1px] h-5 mx-2" />
      <Link to={`/${lang}/terms-of-use`} className="text-warm_white text-sm">
        {t("termsOfUse")}
      </Link>
    </div>
  );
};

export default Footer;
