import React, { useState } from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, Bars3Icon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { Profile } from "./layout/Profile";
import ExternalLinkIcon from "assets/home/externalLinkIcon";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Dropdown = ({ label, options, block }) => (
  <Menu
    as="div"
    className={`relative ${block ? "inline-block" : ""} text-left`}
  >
    {({ open }) => (
      <>
        <div>
          <Menu.Button
            className={`inline-flex w-full justify-center gap-x-1.5 rounded-md bg-transparent px-3 text-m font-medium text-gray-900 hover:text-primary text-warm_white`}
          >
            {label}
            <ChevronDownIcon
              className={`mr-1 h-7 w-5 transition-transform east duration-200 ${
                open ? "-rotate-180" : ""
              }`}
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          show={open}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {options.map((option, index) => (
              <Menu.Item key={`menu_${option.label}_${index}`}>
                {({ active }) => (
                  <Link
                    to={option.link || "#"}
                    onClick={option.onclick}
                    className={`${classNames(
                      active ? "bg-gray-100 text-primary" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )} hover:bg-gray-200`}
                  >
                    {option.label}
                  </Link>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </>
    )}
  </Menu>
);

export const DropdownNav = ({ label, options, customClass }) => {
  const [isMenuOpen, setMenuOpen] = useState("");
  const clearState = () => setMenuOpen("");

  const isExternalLink = (link) => {
    return !/^((?!localhost|nexiiot.io).)*$/.test(link);
  };

  return (
    <Menu as="div" className={`relative text-left ${customClass}`}>
      {({ open }) => (
        <div>
          <Menu.Button
            className={`inline-flex w-full items-center justify-center gap-x-1.5 rounded-md bg-transparent px-3 text-m font-medium text-gray-900 hover:text-primary text-warm_white`}
          >
            {label}
            <Bars3Icon
              className={`mr-1 h-7 w-5 transition-transform east duration-200 ${
                open ? "-rotate-180" : ""
              }`}
              aria-hidden="true"
            />
          </Menu.Button>

          <Transition
            as={Fragment}
            show={open}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95 -translate-y-2"
            enterTo="transform opacity-100 scale-100 -translate-y-0"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100 -translate-y-0"
            leaveTo="transform opacity-0 scale-95 -translate-y-2"
          >
            <Menu.Items className="absolute -right-4 justify-center z-10 mt-4 md:w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none w-[100vw] h-screen">
              {options.map((option, index) =>
                option.type === "link" ? (
                  <Menu.Item key={`menu_${option.label}_${index}`}>
                    {({ active }) => (
                      <Link
                        to={option.link || "#"}
                        onClick={option.onclick}
                        target={isExternalLink(option.link) ? "_blank" : ""}
                        rel={
                          isExternalLink(option.link)
                            ? "noopener noreferrer"
                            : ""
                        }
                        className={`${classNames(
                          active ? "bg-gray-100 text-primary" : "text-gray-700",
                          "block px-4 py-2"
                        )} hover:bg-gray-200 font-normal text-lg flex items-center gap-1`}
                      >
                        {option.label}
                        {isExternalLink(option.link) && (
                          <ExternalLinkIcon size={18} />
                        )}
                      </Link>
                    )}
                  </Menu.Item>
                ) : (
                  <div key={`section_${index}`}>
                    <div
                      className=" flex text-gray-700 px-4 py-2 hover:bg-gray-200 font-normal text-lg  justify-between"
                      onClick={() =>
                        isMenuOpen === option.label
                          ? clearState()
                          : setMenuOpen(option.label)
                      }
                    >
                      {option.label}
                      <ChevronDownIcon
                        className={`mr-1 h-7 w-5 transition-transform east duration-200 ${
                          isMenuOpen === option.label ? "-rotate-180" : ""
                        }`}
                        aria-hidden="true"
                      />
                    </div>
                    <div
                      className={`text-gray-700 bg-gray-100 font-normal text-lg ${
                        isMenuOpen === option.label ? "block" : "hidden"
                      }`}
                      key={`menu_${index}`}
                    >
                      {option.options.map((menu, i) => (
                        <a key={`${option}_${i}`} href={menu.link}>
                          <div key={`12${i}`} className="px-4 py-2">
                            {menu.label}
                          </div>
                        </a>
                      ))}
                    </div>
                  </div>
                )
              )}

              <Profile
                className="w-full p-6 justify-center"
                btnClassName="w-[70vw]"
              />
            </Menu.Items>
          </Transition>
        </div>
      )}
    </Menu>
  );
};
