import React from "react";
import { useQuery } from "@apollo/client";
import { GET_BLOG_LIST } from "utils/apollo/graphql/blog";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { NewsCard } from "components/NewsCard";
import "../customStyle.css";

export const News = () => {
  const { loading, data } = useQuery(GET_BLOG_LIST, {
    variables: { category: "news and updates", limit: 4 },
  });

  if (loading) return null;

  const news = data?.blogs ?? [];

  if (!news.length) return null;

  return (
    <div className="max-w-screen-xl mx-10 xl:mx-auto pb-20">
      <header className="w-full flex items-center justify-between gap-2 md:gap-4">
        <h1 className="font-medium text-2xl md:text-4xl text-primary">
          News and Updates
        </h1>
        <Link
          to="/blog"
          className="text-primary font-normal text-md md:text-lg"
        >
          See All <ArrowRightIcon className="w-5 inline ml-2 mb-1" />
        </Link>
      </header>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-10 mx-auto">
        {news.map((item, index) => (
          <NewsCard
            key={index}
            item={item}
            image={`${process.env.REACT_APP_STRAPI_URL}${item.feature_image?.url}`}
          />
        ))}
      </div>
    </div>
  );
};
