import { Layout } from "components/layout";
import { Application } from "components/section/application";
import { FAQ } from "components/section/faq";
import { Features } from "components/section/features";
import { Hero } from "components/section/hero";
import { News } from "components/section/news";
import { Preview } from "components/section/preview";
import { Start } from "components/section/start";

export const HomePage = (props) => {
  return (
    <Layout>
      <Hero />
      <Preview />
      <Features />
      <Application />
      <FAQ />
      <News />
      <Start />
    </Layout>
  );
};
