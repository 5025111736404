import { gql } from "@apollo/client";
const GET_HEADERS = gql`
  query header($locale: String) {
    home(locale: $locale) {
      header
      sub_header
      header_description
    }
  }
`;
const GET_PREVIEW = gql`
  query preview($locale: String) {
    preview(locale: $locale) {
      id
      title
      video {
        url
      }
    }
  }
`;
const GET_FEATURES = gql`
  query feature($locale: String) {
    feature(locale: $locale) {
      id
      title
      description
      featureCard {
        title
        description
        cta_title
        cta_link
        featureImage {
          url
        }
      }
    }
  }
`;

const GET_CUSTOMERS = gql`
  query {
    customer {
      description
      customerCard {
        name
        url
        logo {
          url
        }
      }
    }
  }
`;
const GET_APPLICATION = gql`
  query {
    application {
      title
      description
      store {
        name
        link_download
      }
    }
  }
`;

export {
  GET_HEADERS,
  GET_PREVIEW,
  GET_FEATURES,
  GET_CUSTOMERS,
  GET_APPLICATION,
};
