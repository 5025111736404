import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearSearchResults, searchBlogs } from "redux/reducer/blogReducer";
import { BlogCard } from "components/BlogCard";
import Loading from "components/Loading";
import { useTranslation } from "react-i18next";
import { MediumIcon } from "assets/social/medium";

const SearchModal = ({ isOpen, onClose, articles = [] }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const { searchResults, searchLoading } = useSelector((state) => state.blogs);
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      setSearchQuery("");
    }
    return () => {
      dispatch(clearSearchResults());
    };
  }, [isOpen, dispatch]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    dispatch(searchBlogs(query));
  };

  const filteredArticles = articles.filter(
    (article) =>
      searchQuery &&
      article.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const renderSearchResults = () => {
    if (searchLoading) {
      return <Loading />;
    }

    if (searchResults?.length > 0 || filteredArticles.length > 0) {
      return (
        <div className="mt-4 h-[calc(100%-60px)] overflow-scroll rounded-lg flex flex-col gap-2 overscroll-none">
          {searchResults?.length > 0 && (
            <>
              <div className="text-lg font-bold">{t("blog")}</div>
              {searchResults.map((blog, index) => (
                <BlogCard key={index} item={blog} search />
              ))}
            </>
          )}

          {filteredArticles.length !== 0 && (
            <>
              <div className="text-lg font-bold flex items-center gap-1">
                <MediumIcon /> Medium
              </div>
              {filteredArticles.map((article, index) => (
                <BlogCard key={index} item={article} search isMedium />
              ))}
            </>
          )}
        </div>
      );
    }

    return (
      <div className="w-full flex align-items-center justify-center mt-20">
        {t("noResult")}
        {searchQuery && ` ${t("for")} '${searchQuery}'`}
      </div>
    );
  };

  return (
    <div
      className={`${
        isOpen ? "block" : "hidden"
      } fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center z-50`}
    >
      <div className="bg-white p-4 rounded-xl w-screen max-w-3xl h-screen md:max-h-[80%] overscoll-none">
        <div className="flex mb-3">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder={`${t("searchBlogs")} ...`}
            className="flex-grow border border-gray-300 rounded-md px-3 py-2 mr-2"
          />
          <button
            onClick={onClose}
            className="text-gray-600 hover:text-gray-800 px-4 py-2 rounded-md"
          >
            {t("cancel")}
          </button>
        </div>
        {renderSearchResults()}
      </div>
    </div>
  );
};

export default SearchModal;
