import { useQuery } from "@apollo/client";
import FeatureCard from "components/FeatureCard";
import { GET_FEATURES } from "utils/apollo/graphql/home";

export const Features = () => {
  const {
    loading,
    data = { feature: { title: "", description: "", featureCard: [] } },
  } = useQuery(GET_FEATURES);

  const feature = data?.feature || {
    title: "",
    description: "",
    featureCard: [],
  };

  const sortedFeatureCards = (feature.featureCard || [])
    .slice() // Create a shallow copy of the featureCard array
    .sort((a, b) => a.order - b.order); // Sort the copied array based on the order field

  if (loading) {
    return <></>;
  }

  return (
    <div className="max-w-screen-xl mx-8 md:mx-10 lg:mx-auto">
      <div className="mt-20 mb-4 mx-auto text-center max-w-prose">
        <h2 className="text-3xl md:text-5xl font-medium text-primary">
          {feature.title}
        </h2>
        {feature.description && (
          <p className="text-lg md:text-xl text-description mt-6">
            {feature.description}
          </p>
        )}
      </div>

      <div className="grid grid-cols-1 gap-1 md:gap-4 md:grid-cols-1 mx-auto mt-10">
        {sortedFeatureCards.map((card, idx) => (
          <FeatureCard
            title={card.title}
            description={card.description}
            image={`${process.env.REACT_APP_STRAPI_URL}${card.featureImage?.url}`}
            order={idx % 2}
            cta_title={card.cta_title}
            cta_link={card.cta_link}
            key={`feature_${idx}`}
          />
        ))}
      </div>
    </div>
  );
};
