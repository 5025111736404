import appstore from "../../assets/application/appstore.svg";
import googleplay from "../../assets/application/google-play.svg";

const AppButton = ({ app }) => {
  const buttonImg = { appstore: appstore, googleplay: googleplay };

  return (
    <a href={app.link_download} target="_blank" rel="noreferrer">
      <img
        src={buttonImg[app.name]}
        alt={`icon_${app.name}`}
        className="w-full h-auto"
      />
    </a>
  );
};

export default AppButton;
