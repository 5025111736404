import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import { store } from "redux/store";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n"; // import your i18n configuration
import "react-loading-skeleton/dist/skeleton.css";

const backendUrl = `${process.env.REACT_APP_STRAPI_URL}/graphql`;

export const client = new ApolloClient({
  uri: backendUrl,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      variables: {
        locale: i18n.language || "th",
      },
    },
    query: {
      variables: {
        locale: i18n.language || "th",
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </BrowserRouter>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
